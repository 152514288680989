import React, {useState} from 'react';
import MyTopNav from "../Temp/MyTopNav";
import TestPageQuestion from "./TestPageInc/TestPageQuestion";

const TestPage = () => {
  const [isStartBlock, setIsStartBlock] = useState(true)
  const [currentQuestion, setCurrentQuestion] = useState(1)

  const startTestingHandler = () => {
    setIsStartBlock(false)
    setCurrentQuestion(1)
  }

  const [testArray, setTestArray] = useState([
    {
      id: 1,
      question: 'Questions 1',
      answers: [
        {id: 11, answer: 'answer 1 1', selected: false},
        {id: 22, answer: 'answer 1 2', selected: false},
        {id: 33, answer: 'answer 1 3', selected: false},
      ]
    },
    {
      id: 2,
      question: 'Questions 2',
      answers: [
        {id: 111, answer: 'answer 2 1', selected: false},
        {id: 222, answer: 'answer 2 2', selected: false},
        {id: 333, answer: 'answer 2 3', selected: false},
      ]
    },
    {
      id: 3,
      question: 'Questions 3',
      answers: [
        {id: 1111, answer: 'answer 3 1', selected: false},
        {id: 2222, answer: 'answer 3 2', selected: false},
        {id: 3333, answer: 'answer 3 3', selected: false},
      ]
    }
  ])

  const setAnswerHandler = (q, a) => {
    console.log(q)
    console.log(a)
  }

  const updateQuestionsArrayHandler = (questionId, answerId) => {

    const questionIdx = testArray.findIndex(el => el.id === questionId)
    if(questionIdx > -1){
      const answerIdx = testArray[questionIdx].answers.findIndex(ele => ele.id === answerId)
      if(answerIdx > -1){
        const updatedTestArray = [...testArray];

        updatedTestArray[questionIdx].answers.map(a => {
          a.selected = false
        })

        updatedTestArray[questionIdx].answers[answerIdx].selected = true
        setTestArray(updatedTestArray);
      }
    }
  }

  const nextQuestionHandler = (qId) => {
    if(testArray.length >= qId + 1) {
      setCurrentQuestion(qId + 1)
    }else{
      finishTestingHandler()
    }
  }

  const finishTestingHandler = () => {
    console.log('finishTestingHandler')
  }

  return (
    <>
      <MyTopNav/>
      <div className="preDashboard testPage">
        {isStartBlock &&
          <div className="testPage__block testPage__start">
            <img src="/images/trio.png" alt="" className="ibg"/>
            <div className="testPage__start-bottom">
              <button
                type="button"
                className="brand-button"
                onClick={() => startTestingHandler()}
              >
                Make a test
              </button>
            </div>
          </div>
        }
        {!isStartBlock &&
          <div className="testPage__block testPage__question">
            {testArray.map(question =>
              (
                <div key={question.id}>
                  {currentQuestion === question.id &&
                    <TestPageQuestion
                      question={question}
                      updateQuestionsArray={updateQuestionsArrayHandler}
                      nextQuestionHandler={nextQuestionHandler}
                    />
                  }
                </div>
              )
            )}
          </div>
        }
      </div>
    </>
  );
};

export default TestPage;
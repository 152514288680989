import React, {useMemo, useState} from 'react';
import DashTopBlock from "./DashboardBlocks/DashTopBlock";
import DashLeftMenu from "./DashboardBlocks/DashLeftMenu";
import DashRightMenu from "./DashboardBlocks/DashRightMenu";
import Avatar from "react-avatar-edit";

const Settings = () => {

  const [userName, setUserName] = useState('Martin')
  const [equants, setEquants] = useState(64652)

  const [userInfo, setUserInfo] = useState({
    name: 'Chuck Norris In Building',
    login: 'owner-of-the-world',
    date: '',
    email: 'chackmail@chackmail.norris',
  })

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUserInfo(userInfo => ({
      ...userInfo,
      [name]: value
    }));
  }

  const submitHandler = (e) => {
    e.preventDefault()
    console.log(userInfo)
    console.log(imageFile)
  }


  const onClose = () => {
    setSrc(null);
    setPreview(null);
  }

  const onCrop = (preview) => {
    setPreview(preview)
  }

  const onBeforeFileLoad = (elem) => {
    console.log('onBeforeFileLoad')
  }

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSrc(reader.result);
      };

      setImageFile(file);
      reader.readAsDataURL(file);
    }
  };

  const removeAvatarDataHandler = () => {
    setSrc(null)
    setPreview(null)
    setImageFile(null)
  }

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid">
          <DashTopBlock userName={userName} equants={equants}/>
          <div className="dashWrapper">
            <DashLeftMenu/>


            <div className="dashContent">
              <div className="dashboard-blocks dashboard-settings">
                <div className="dash-section dashboard-settings-result">
                  <h1>Settings</h1>
                  <div className="dash-section dashboard-settings-result-inner">
                    <form className="dashboard-settings__form">
                    <div className="">
                      <input type="file" id="avatar__input" onChange={handleFileChange} />
                      {src && (
                        <div className="dashboard-settings__form-avatar-holder ">
                          <Avatar
                            src={src}
                            width={300}
                            height={195}
                            onCrop={(e) => onCrop(e)}
                            onClose={() => onClose()}
                            onBeforeFileLoad={() => onBeforeFileLoad()}
                            label="Add avatar"
                            labelStyle={{color:'#F3F7FF',fontSize:18, fontWeight:700}}
                            borderStyle={{cursor:'pointer'}}
                          />
                        </div>
                      )}
                        <div className="avatar_block">
                          <label htmlFor="avatar__input" className="dashboard-settings__form-avatar-preview">
                            {preview
                              ?
                              <img src={preview} alt="Preview" />
                              :
                              <div className="no-photo-holder">
                                <img className="no-photo ibg" src='/images/trio.png' alt="Preview" />
                              </div>
                            }
                          </label>
                          <div className="dashboard-settings__user-info">
                            <div className="dashboard-settings__user-info-name">
                              {userInfo.name ? userInfo.name : ''}
                            </div>
                            <div className="dashboard-settings__user-info-hash">
                              #{userInfo.login ? userInfo.login : '-----'}
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="dashboard-settings__form-control">
                      <div className="dashboard-settings__form-control-label">
                        Name
                      </div>
                      <input
                        className="dashboard-settings__form-input"
                        type="text"
                        name="name"
                        placeholder="Name"
                        required={true}
                        value={userInfo.name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="dashboard-settings__form-control">
                      <div className="dashboard-settings__form-control-label">
                        Username / login
                      </div>
                      <input
                        className="dashboard-settings__form-input"
                        type="text"
                        placeholder="Username / login"
                        required={true}
                        value={userInfo.login}
                        name="login"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="dashboard-settings__form-control">
                      <div className="dashboard-settings__form-control-label">
                        Email
                      </div>
                      <input
                        className="dashboard-settings__form-input"
                        type="email"
                        placeholder="example@example.com"
                        required={true}
                        value={userInfo.email}
                        name="email"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <button
                      className="brand-button"
                      onClick={(e) => submitHandler(e)}
                    >
                      Save
                    </button>
                  </form>
                  </div>
                </div>

                {/*<div className="dash-section trio-block">*/}
                {/*  <img className="dashboard-page__bottom-img" src="/images/trio_sm.png" alt=""/>*/}
                {/*</div>*/}
              </div>


            </div>

            <DashRightMenu/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
import React, {useMemo} from 'react';

const TestPageQuestion = (props) => {

  const setAnswerHandler = (questionId, answerId) => {
    props.updateQuestionsArray(questionId, answerId)
  }
  const nextQuestionHandler = (questionId) => {
    props.nextQuestionHandler(questionId)
  }

  const isDisabledButton = useMemo(() => {
    return !props.question.answers.find(el => el.selected === true);
  }, [props])

  return (
    <>
      <h1>{props.question.question}</h1>
      <div className="testPage__question-answers">
        {props.question.answers.length &&
          props.question.answers.map(answer =>
            <div
              key={answer.id}
                className={'testPage__question-answer ' + (!answer.selected ? '' : 'testPage__question-answer-selected')}
              onClick={() => setAnswerHandler(props.question.id, answer.id)}
            >
              {answer.answer}
            </div>
          )
        }
      </div>
      <button
        type="button"
        className={'brand-button' + (isDisabledButton ? ' --disabled' : '')}
        onClick={() => nextQuestionHandler(props.question.id)}
        disabled={isDisabledButton}
      >
        Next
      </button>
    </>
  );
};

export default TestPageQuestion;
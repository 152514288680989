import React from 'react';

const DashRightMeHim = () => {
  return (
    <div className="dashRightMeHim dashContent">
      <div className="dashboard-blocks">
        <div className="dash-section dashRightMeHim__result">
          <div className="dashRightMeHim__result-inner">
            <div className="dashRightMeHim__top  dashboard-page">
              <h1>Me</h1>

              <div className="dashboard-page__inner">
                <div className="dashboard-page__inner-column">
                  <div className="dashboard-page__inner-column-item">
                    CA = 10
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    CN = 54
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    CR = 32
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    CO = 12
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    PL = 43
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    PR = 99
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    PC = 76
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    AC = 23
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    AA = 47
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    e = 24
                  </div>
                </div>
                <div className="dashboard-page__inner-info">
                  <div className="circle-diagram">
                    <div className="circle-diagram__inner">
                    <img src="/images/dia.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashRightMeHim__bottom">
              <h1>Him</h1>

              <div className="dashboard-page__inner">
                <div className="dashboard-page__inner-column">
                  <div className="dashboard-page__inner-column-item">
                    CA = 10
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    CN = 54
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    CR = 32
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    CO = 12
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    PL = 43
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    PR = 99
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    PC = 76
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    AC = 23
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    AA = 47
                  </div>
                  <div className="dashboard-page__inner-column-item">
                    e = 24
                  </div>
                </div>
                <div className="dashboard-page__inner-info">
                  <div className="circle-diagram">
                    <div className="circle-diagram__inner">
                    <img src="/images/dia.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashRightMeHim;
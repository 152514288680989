import React from 'react';
import {useTimer} from "react-timer-hook";

const Timer = ({ expiryTimestamp }) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

  return (
    <div className="brand-timer">
      <div>
        <span>{days.toString().length < 2 ? '0'+days : days }</span>
        :<span>{hours.toString().length < 2 ? '0'+hours : hours }</span>
        :<span>{minutes.toString().length < 2 ? '0'+minutes : minutes }</span>
        :<span>{seconds.toString().length < 2 ? '0'+seconds : seconds }</span>
      </div>
    </div>
  );
};

export default Timer;
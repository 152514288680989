import React from 'react';
import {useNavigate} from "react-router-dom";

const MyTopNav = () => {

  const navigate = useNavigate()

  const routes = [
    {
      name: 'Connect wallet',
      path: '/'
    },
    {
      name: 'Add info',
      path: '/add-info'
    },
    {
      name: 'Make a test',
      path: '/test'
    },
    {
      name: 'Dashboard',
      path: '/dashboard'
    },
  ]

  const pushToPageHandler = (path) => {
    if(path) navigate(path)

  }


  return (
    <div className="myTopNav">
      <ul>
        {
          routes.map((route) => {
            return <li key={route.path} onClick={() => pushToPageHandler(route.path)}>{route.name}</li>
          })
        }
      </ul>
    </div>
  );
};

export default MyTopNav;
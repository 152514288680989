import React from 'react';
import MyTopNav from "../Temp/MyTopNav";

const ConnectWallet = () => {

  const connectWalletHandler = () => {
    console.log('connectWalletHandler')
  }

  return (
    <>
      <MyTopNav />
      <div className="preDashboard connectWallet">
        <div className="connectWallet__block">
          <h1>Connect wallet</h1>
          <span className="connectWallet__title-description">Connect your wallet</span>
          <button
            className="brand-button"
            onClick={() => connectWalletHandler()}
          >
            Connect wallet
          </button>
          <span className="connectWallet__bottom-description">Hiht what wallet connect</span>
        </div>
      </div>
    </>
  );
};

export default ConnectWallet;
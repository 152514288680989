import React, {useMemo, useRef, useState} from 'react';
import MyTopNav from "../Temp/MyTopNav";
import Avatar from "react-avatar-edit";

const AddInfo = () => {

  const [userInfo, setUserInfo] = useState({
    name: '',
    login: '',
    date: '',
    email: '',
  })

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUserInfo(userInfo => ({
      ...userInfo,
      [name]: value
    }));
  }

  const submitHandler = (e) => {
    e.preventDefault()
    console.log(userInfo)
    console.log(imageFile)
  }

  const isSubmitButtonDisabled = useMemo(() => {
    if (!userInfo.name) return true
    if (!userInfo.date) return true
    if (!userInfo.email) return true
    if (!userInfo.login) return true
    return false
  }, [userInfo])


  const onClose = () => {
    setSrc(null);
    setPreview(null);
  }

  const onCrop = (preview) => {
    setPreview(preview)
  }

  const onBeforeFileLoad = (elem) => {
    console.log('onBeforeFileLoad')
  }

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSrc(reader.result);
      };

      setImageFile(file);
      reader.readAsDataURL(file);
    }
  };

  const removeAvatarDataHandler = () => {
    setSrc(null)
    setPreview(null)
    setImageFile(null)
  }

  return (
    <>
      <MyTopNav/>
      <div className="preDashboard addInfo">
        <div className="addInfo__block">
          <h1>Add info</h1>
          <form className="addInfo__form">
            <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Name
              </div>
              <input
                className="addInfo__form-input"
                type="text"
                name="name"
                placeholder="Name"
                required={true}
                value={userInfo.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Username / login
              </div>
              <input
                className="addInfo__form-input"
                type="text"
                placeholder="Username / login"
                required={true}
                value={userInfo.login}
                name="login"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Birth date
              </div>
              <input
                className="addInfo__form-input"
                type="date"
                placeholder="01.01.1990"
                required={true}
                value={userInfo.date}
                name="date"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Email
              </div>
              <input
                className="addInfo__form-input"
                type="email"
                placeholder="example@example.com"
                required={true}
                value={userInfo.email}
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="addInfo__form-avatar">
              <input type="file" id="avatar__input" onChange={handleFileChange} />
              {src && (
                <div className="addInfo__form-avatar-holder ">
                  <Avatar
                    src={src}
                    width={300}
                    height={195}
                    onCrop={(e) => onCrop(e)}
                    onClose={() => onClose()}
                    onBeforeFileLoad={() => onBeforeFileLoad()}
                    label="Add avatar"
                    labelStyle={{color:'#F3F7FF',fontSize:18, fontWeight:700}}
                    borderStyle={{cursor:'pointer'}}
                  />
                </div>
              )}
              {preview && (
                <div className="addInfo__form-avatar-preview">
                  <img src={preview} alt="Preview" />
                  <div className="addInfo__form-avatar-preview-actions">
                    <label
                      className="addInfo__form-avatar-preview-action-change"
                      htmlFor="avatar__input"
                    >
                      Change
                    </label>
                    <div
                      className="addInfo__form-avatar-preview-action-remove"
                      onClick={removeAvatarDataHandler}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              )}
              {!preview && (
                <label htmlFor="avatar__input">
                  <div className="addInfo__form-avatar-title">
                    Add avatar
                  </div>
                  <div className="addInfo__form-avatar-blue">
                    drop file here or upload
                  </div>
                </label>
                )
              }
            </div>
            <button
              className={'brand-button ' + (isSubmitButtonDisabled ? ' --disabled' : "")}
              disabled={isSubmitButtonDisabled ? true : false}
              onClick={(e) => submitHandler(e)}
            >
              Create
            </button>
          </form>
        </div>

      </div>
    </>
  );
};

export default AddInfo;
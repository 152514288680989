import React, {useMemo, useState} from 'react';
import DashTopBlock from "./DashboardBlocks/DashTopBlock";
import DashLeftMenu from "./DashboardBlocks/DashLeftMenu";
import DashRightMenu from "./DashboardBlocks/DashRightMenu";

const Search = () => {

  const [userName, setUserName] = useState('Martin')
  const [equants, setEquants] = useState(64652)

  const [users,setUsers] = useState([
    {
      name: 'Marcus Dias',
      email: 'asjdhk@asdas.asd',
      wallet: '1111111111111111111111111111111',
      trust: 15,
      relation: 25
    },
    {
      name: 'Jordyn Press',
      email: 'Jordyn Press@mail.ru',
      wallet: '22222222222222222222222222222222',
      trust: 35,
      relation: 15
    },
    {
      name: 'Davis Korsgaard',
      email: 'Davis Korsgaard@gmail.com',
      wallet: '33333333333333333333333333333',
      trust: 45,
      relation: 5
    },
    {
      name: 'Carla Aminoff',
      email: 'Carla Aminoff@ukr.net',
      wallet: '44444444444444444444444444444444444',
      trust: 33,
      relation: 65
    },
    {
      name: 'Marcus Dias',
      email: 'asjdhk@asdas.asd',
      wallet: '55555555555555555555555555555555555555',
      trust: 33,
      relation: 12
    },
    {
      name: 'Davis Lipshutz',
      email: 'Davis Lipshutz@asdas.asd',
      wallet: '66666666666666666666666666666666666',
      trust: 15,
      relation: 25
    },
    {
      name: 'Alfredo Dias',
      email: 'Alfredo Dias@.asdas.asd',
      wallet: '77777777777777777777777777777777',
      trust: 15,
      relation: 25
    },
    {
      name: 'Jordyn Press',
      email: 'Alfredo Dias@.asdas.asd',
      wallet: '888888888888888888888888888888888',
      trust: 15,
      relation: 25
    },
    {
      name: 'Talan Calzoni',
      email: 'Talan Calzoni@.asdas.asd',
      wallet: '99999999999999999999999999999999999',
      trust: 15,
      relation: 25
    },
    {
      name: 'Talan Franci',
      email: 'Talan Franci@.asdas.asd',
      wallet: '101010101010101010101010101010110',
      trust: 15,
      relation: 25
    },
  ])

  const [searchInput, setSearchInput] = useState('')

  const sortedUsers = useMemo(() => {
    if(searchInput !== ''){
      return users.filter(user => user.email.toLowerCase().includes(searchInput.toLowerCase()) || user.wallet.toLowerCase().includes(searchInput.toLowerCase()) || user.name.toLowerCase().includes(searchInput.toLowerCase()))
    }else{
      return users
    }
  }, [searchInput,users])

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid">
          <DashTopBlock userName={userName} equants={equants}/>
          <div className="dashWrapper">
            <DashLeftMenu/>



            <div className="dashContent">
              <div className="dashboard-blocks dashboard-search">
                <div className="dash-section dashboard-search-block">
                  <div className="dashboard-search-block-inner">
                    <h1>Search</h1>
                    <div className="brand-input__holder">
                      <svg className="brand-input__search" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_2_883)">
                          <circle cx="11.5" cy="11.5" r="9.5" stroke="#B9C3D7" strokeWidth="3"/>
                          <path d="M20 20L22 22" stroke="#B9C3D7" strokeWidth="3" strokeLinecap="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_2_883">
                            <rect width="24" height="24" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        type="text"
                        className="brand-input"
                        placeholder="Name, surname, email or wallet address"
                        value={searchInput}
                        onChange={(e)=>setSearchInput(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="dash-section dashboard-search-result">
                  {sortedUsers.length !== 0
                    ?
                      <div className="dashboard-search-result-inner">
                        {sortedUsers.map((user,idx) =>
                          <div className="list-user" key={idx}>
                            <div className="list-user__ava">
                              <img src="/images/trio.png" alt="" className="ibg list-user__ava-img"/>
                            </div>
                            <h4>{user.name ? user.name : 'no name'}</h4>
                            <div className="list-user__numbers">
                              <div className="list-user__number">
                                <div className="list-user__number-green">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <circle cx="9.99935" cy="5.00033" r="3.33333" fill="white"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.7507 18.3333C12.3757 18.3333 11.6883 18.3333 11.2611 17.9062C10.834 17.4791 10.834 16.7916 10.834 15.4167C10.834 14.0417 10.834 13.3543 11.2611 12.9271C11.6883 12.5 12.3757 12.5 13.7507 12.5C15.1256 12.5 15.813 12.5 16.2402 12.9271C16.6673 13.3543 16.6673 14.0417 16.6673 15.4167C16.6673 16.7916 16.6673 17.4791 16.2402 17.9062C15.813 18.3333 15.1256 18.3333 13.7507 18.3333ZM15.3907 14.7882C15.5805 14.5983 15.5805 14.2906 15.3907 14.1007C15.2008 13.9109 14.8931 13.9109 14.7032 14.1007L13.1025 15.7014L12.7981 15.397C12.6082 15.2072 12.3005 15.2072 12.1106 15.397C11.9208 15.5868 11.9208 15.8946 12.1106 16.0845L12.7588 16.7326C12.9486 16.9225 13.2564 16.9225 13.4462 16.7326L15.3907 14.7882Z" fill="white"/>
                                    <path d="M12.845 11.2558C12.0032 10.9865 11.0327 10.833 9.99935 10.833C6.77769 10.833 4.16602 12.3254 4.16602 14.1663C4.16602 15.9501 6.61805 17.4067 9.70113 17.4954C9.6785 17.3901 9.66103 17.2857 9.64724 17.1831C9.58251 16.7016 9.5826 16.1134 9.58269 15.488V15.3447C9.5826 14.7192 9.58251 14.131 9.64724 13.6496C9.71926 13.1138 9.89142 12.5274 10.3759 12.0429C10.8605 11.5584 11.4469 11.3862 11.9826 11.3142C12.2426 11.2793 12.5337 11.2632 12.845 11.2558Z" fill="white"/>
                                  </svg>
                                  {user.trust ? user.trust : 0} %
                                </div>
                                <span>trust</span>
                              </div>
                              <div className="list-user__number">
                                <div className="list-user__number-red">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M10.0003 8.33332C11.8413 8.33332 13.3337 6.84094 13.3337 4.99999C13.3337 3.15904 11.8413 1.66666 10.0003 1.66666C8.15938 1.66666 6.66699 3.15904 6.66699 4.99999C6.66699 6.84094 8.15938 8.33332 10.0003 8.33332Z" fill="white"/>
                                    <path d="M2.27348 4.84835C2.18976 4.51348 1.85043 4.30988 1.51556 4.3936C1.18068 4.47731 0.977083 4.81665 1.0608 5.15152L1.37911 6.42474C1.96641 8.77394 3.74702 10.6246 6.04214 11.3155L6.04214 15.0433C6.04212 15.792 6.04209 16.4164 6.10873 16.912C6.17907 17.4352 6.3338 17.9075 6.71335 18.2871C7.09291 18.6666 7.56525 18.8213 8.08841 18.8917C8.58406 18.9583 9.20841 18.9583 9.95715 18.9583H10.0438C10.7925 18.9583 11.4169 18.9583 11.9125 18.8917C12.4357 18.8213 12.908 18.6666 13.2876 18.2871C13.6672 17.9075 13.8219 17.4352 13.8922 16.912C13.9589 16.4164 13.9588 15.792 13.9588 15.0433L13.9588 11.5491C14.7764 11.7929 15.401 12.5032 15.5052 13.3892L16.0464 17.9896C16.0867 18.3324 16.3973 18.5777 16.7402 18.5373C17.083 18.497 17.3282 18.1864 17.2879 17.8436L16.7466 13.2431C16.543 11.5125 15.0763 10.2083 13.3338 10.2083H6.73758C4.70137 9.7303 3.09997 8.1543 2.59178 6.12157L2.27348 4.84835Z" fill="white"/>
                                  </svg>
                                  {user.relation ? user.relation : 0} %
                                </div>
                                <span>trust</span>
                              </div>
                            </div>
                            <button className="list-user__action">
                              Send request
                            </button>
                          </div>
                        )}
                      </div>
                    :
                      <div className="dashboard-search-result__no-result">
                        No result
                      </div>
                  }
                </div>
              </div>
            </div>



            <DashRightMenu/>
          </div>

        </div>
      </div>
    </>
  );
};

export default Search;
import React, {useMemo, useState} from 'react';
import DashTopBlock from "./DashboardBlocks/DashTopBlock";
import DashLeftMenu from "./DashboardBlocks/DashLeftMenu";
import DashRightMenu from "./DashboardBlocks/DashRightMenu";
import Modal from "../../components/Modal/Modal";
import Timer from "../../components/Timer/Timer";

const Equants = () => {

  const [userName, setUserName] = useState('Martin')
  const [equants, setEquants] = useState(64652)

  const time = new Date();
  time.setSeconds(time.getSeconds() + 1111600); // 1111600 secs add



  return (
    <>
      <div className="dashboard">
        <div className="container-fluid">
          <DashTopBlock userName={userName} equants={equants}/>
          <div className="dashWrapper">
            <DashLeftMenu/>


            <div className="dashContent">
              <div className="dashboard-blocks dashboard-equants">

                <div className="dash-section dashboard-equants-result">
                  <h1>Equants</h1>
                  <div className="dashboard-equants-block">
                    <Timer expiryTimestamp={time}/>
                    <button className="brand-button">
                      Claim
                    </button>
                    <span className="dashboard-equants-block-amount">Amount of EQuants:100</span>
                  </div>
                </div>
                <div className="dash-section trio-block">
                  <img className="dashboard-page__bottom-img" src="/images/trio_sm.png" alt=""/>
                </div>
              </div>
            </div>


            <DashRightMenu/>
          </div>

        </div>
      </div>
    </>
  );
};

export default Equants;
import React, {useState} from 'react';
import s from './Modal.module.css';


function Modal({children, visible, setVisible}, props) {
  const rootClasses = [s.Modal]

  const [content, setContent] = useState(children);
  if (visible) {
    rootClasses.push(s.active);
  }

  const closeModal = (event) => {
    event.preventDefault();
    setVisible(false);
    setContent('');
  }

  return (
    <div
      className={rootClasses.join(' ')}
      onClick={closeModal}
    >
      <div
        className={s.ModalWrapper}
        onClick={e => e.stopPropagation()}
      >
        <div
          className={s.close}
          onClick={closeModal}
        >
          X
        </div>
        <div className={s.ModalContent}>
          <div id="modalContent" className={s.ModalBody}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
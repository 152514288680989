import React, {useState} from 'react';
import DashTopBlock from "./DashboardBlocks/DashTopBlock";
import DashLeftMenu from "./DashboardBlocks/DashLeftMenu";
import DashRightMenu from "./DashboardBlocks/DashRightMenu";

const Education = () => {

  const [userName, setUserName] = useState('Martin')
  const [equants, setEquants] = useState(64652)

  return (
    <div className="dashboard">
      <div className="container-fluid">
        <DashTopBlock userName={userName} equants={equants}/>
        <div className="dashWrapper">
          <DashLeftMenu/>

          <div className="dashContent">
            <div className="dashboard-blocks education">
              <div className="dash-section">
                <h1>Education</h1>
                <div className="education__list">
                    <div className="education__category">
                      <h4>Open communication</h4>
                      <div className="education__category-items">
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status --done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                              <path d="M5 10.0004L8.53553 13.5359L15.6058 6.46484" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            done
                          </div>
                        </div>
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>

                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="education__category">
                      <h4>Personality scan</h4>
                      <div className="education__category-items">
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status --done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                              <path d="M5 10.0004L8.53553 13.5359L15.6058 6.46484" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            done
                          </div>
                        </div>
                        <div className="education__category-item">
                          {/*<img className="ibg" src="/images/trio.png" alt=""/>*/}
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>
                        <div className="education__category-item">
                          {/*<img className="ibg" src="/images/trio.png" alt=""/>*/}
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>

                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="education__category">
                      <h4>Charisma training</h4>
                      <div className="education__category-items">
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status --done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                              <path d="M5 10.0004L8.53553 13.5359L15.6058 6.46484" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            done
                          </div>
                        </div>
                        <div className="education__category-item">
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>

                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            still have done
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>

          </div>
          <DashRightMenu/>
        </div>
      </div>
    </div>
  );
};

export default Education;
import './css/bootstrap.css'
import './css/reset.css'
import './css/style.css'

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";

import ConnectWallet from "./pages/PreDashboard/ConnectWallet";
import AddInfo from "./pages/PreDashboard/AddInfo";
import TestPage from "./pages/PreDashboard/TestPage";
import Main from "./pages/Dashboard/Main";
import Search from "./pages/Dashboard/Search";
import Matching from "./pages/Dashboard/Matching";
import MyRelations from "./pages/Dashboard/MyRelations";
import Education from "./pages/Dashboard/Education";
import Request from "./pages/Dashboard/Request";
import Equants from "./pages/Dashboard/Equants";
import Settings from "./pages/Dashboard/Settings";
import Chat from "./pages/Dashboard/Chat";
import Personality from "./pages/Dashboard/Personality";


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <ConnectWallet />,
    },
    {
      path: "/add-info",
      element: <AddInfo />,
    },
    {
      path: "/test",
      element: <TestPage />,
    },
    {
      path: "/dashboard",
      element: <Main />,
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/matching",
      element: <Matching />,
    },
    {
      path: "/my-relations",
      element: <MyRelations />,
    },
    {
      path: "/education",
      element: <Education />,
    },
    {
      path: "/request",
      element: <Request />,
    },
    {
      path: "/equants",
      element: <Equants />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/chat",
      element: <Chat />,
    },
    {
      path: "/personality",
      element: <Personality />,
    },

  ]);

  return (
      <>
        <RouterProvider router={router} />
      </>
  );
}

export default App;

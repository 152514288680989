import React, {useMemo, useState} from 'react';
import DashTopBlock from "./DashboardBlocks/DashTopBlock";
import DashLeftMenu from "./DashboardBlocks/DashLeftMenu";
import DashRightMenu from "./DashboardBlocks/DashRightMenu";
import Avatar from "react-avatar-edit";
import Moment from "react-moment";

const Chat = () => {

  const [userName, setUserName] = useState('Martin')
  const [equants, setEquants] = useState(64652)

  const [userInfo, setUserInfo] = useState({
    name: 'Chuck Norris In Building',
    login: 'owner-of-the-world',
    date: '',
    wallet: '1111111111111',
    email: 'chackmail@chackmail.norris',
  })

  const [messages, setMessages] = useState([
    {message: 'your new bro!!!', wallet: '2222222222222', timestamp: 1720100830},
    {message: 'hello, who are you, bro?', wallet: '1111111111111', timestamp: 1720100720},
    {message: 'hello bro', wallet: '2222222222222', timestamp: 1720100710},
  ])

  const [newMessage, setNewMessage] = useState('')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addMessageHandler()
    }
  }
  const addMessageHandler = () => {
    if(newMessage){
      const timestamp = Date.now()
      const newMsg = {
        message: newMessage,
        wallet: userInfo.wallet,
        timestamp: timestamp / 1000
      }
      if(newMsg.message && newMsg.wallet){
        const newDialog = [...messages]
        newDialog.unshift(newMsg)
        setMessages(newDialog)
      }
      setNewMessage('')
    }
  }

  
  return (
    <>
      <div className="dashboard">
        <div className="container-fluid">
          <DashTopBlock userName={userName} equants={equants}/>
          <div className="dashWrapper">
            <DashLeftMenu/>


            <div className="dashContent">
              <div className="chat">
                <div className="chat__section ">
                  <h1>Chat</h1>
                  <div className="chat__block">
                    <div className="chat__inner">
                      {
                        messages.length
                        ?
                          messages.map((msg,idx) =>
                            <div
                              key={idx}
                              className={'chat__message ' + (userInfo.wallet === msg.wallet ? 'my-message' : '')}
                            >
                              {msg.message ? msg.message : ''}
                              <span className="chat__message-time">
                                <Moment format="HH:mm" unix>{msg.timestamp ? msg.timestamp : 0}</Moment>
                              </span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M5.24976 9L8.96207 12.7123L16.9177 4.75745M1.53711 9.03772L5.24942 12.75M13.2043 4.79517L9.22681 8.77264" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            </div>
                          )
                          :
                          <div className="chat__clear">
                            <div>
                              <img src="/images/empty-chat.png" alt="" className="chat__clear-img"/>
                              <span>Start your first chat</span>
                            </div>
                          </div>
                      }
                    </div>
                    <div className="chat__input">
                      <input
                        type="text"
                        className="brand-input"
                        placeholder="Message"
                        value={newMessage}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => setNewMessage(e.target.value)}
                      />
                      <svg onClick={() => addMessageHandler()} className="chat__input-send-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M20.3521 10.5208L18.6357 15.6701C17.4255 19.3008 16.8204 21.1161 15.933 21.6319C15.0889 22.1227 14.0463 22.1227 13.2022 21.6319C12.3148 21.1161 11.7097 19.3008 10.4995 15.6701C10.3052 15.0872 10.208 14.7957 10.0449 14.5521C9.88687 14.316 9.68404 14.1131 9.44793 13.9551C9.2043 13.792 8.91282 13.6948 8.32987 13.5005C4.69923 12.2903 2.88392 11.6852 2.36806 10.7978C1.87731 9.95369 1.87731 8.91112 2.36806 8.06698C2.88392 7.17964 4.69923 6.57453 8.32987 5.36432L13.4792 3.64788C17.9776 2.14842 20.2268 1.39869 21.414 2.58595C22.6013 3.77322 21.8516 6.02242 20.3521 10.5208ZM13.0457 10.9022C12.7544 10.6077 12.7571 10.1328 13.0516 9.84153L17.2621 5.67742C17.5566 5.38615 18.0315 5.38878 18.3227 5.6833C18.614 5.97781 18.6114 6.45267 18.3169 6.74394L14.1063 10.9081C13.8118 11.1993 13.337 11.1967 13.0457 10.9022Z" fill="#B9C3D7"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <DashRightMenu/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
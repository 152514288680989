import React, {useMemo, useState} from 'react';
import DashTopBlock from "./DashboardBlocks/DashTopBlock";
import DashLeftMenu from "./DashboardBlocks/DashLeftMenu";
import DashRightMenu from "./DashboardBlocks/DashRightMenu";

const Personality = () => {

  const [userName, setUserName] = useState('Martin')
  const [equants, setEquants] = useState(64652)

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid">
          <DashTopBlock userName={userName} equants={equants}/>
          <div className="dashWrapper">
            <DashLeftMenu/>


            <div className="dashContent">
              <div className="dashboard-blocks dashboard-personality">
                <div className="dash-section dashboard-personality-block">
                  <h1>Personality</h1>
                  <h5>How I see my personality</h5>
                  <div className="dashboard-personality-block-inner --first">

                    <div className="dashboard-personality-block-inner-column">
                      <div className="dashboard-personality-block-inner-column-item">
                        CA = 10
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        CN = 54
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        CR = 32
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        CO = 12
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        PL = 43
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        PR = 99
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        PC = 76
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        AC = 23
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        AA = 47
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        e = 24
                      </div>
                    </div>

                    <div className="circle-diagram">
                      <div className="circle-diagram__inner">
                        <img src="/images/dia.png" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dash-section dashboard-personality-block">
                  <h5>How users see me like with this personality</h5>
                  <div className="dashboard-personality-block-inner">

                    <div className="dashboard-personality-block-inner-column">
                      <div className="dashboard-personality-block-inner-column-item">
                        CA = 10
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        CN = 54
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        CR = 32
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        CO = 12
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        PL = 43
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        PR = 99
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        PC = 76
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        AC = 23
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        AA = 47
                      </div>
                      <div className="dashboard-personality-block-inner-column-item">
                        e = 24
                      </div>
                    </div>

                    <div className="circle-diagram">
                      <div className="circle-diagram__inner">
                        <img src="/images/dia.png" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <DashRightMenu/>
          </div>

        </div>
      </div>
    </>
  );
};

export default Personality;